import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { fetchPersonId, updatePerson } from '../../actions/person'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import fetchStates from '../../types/fetchStates'
import Preloader from '../partials/Preloader'
import classnames from 'classnames'
import { IconVisibility, IconVisibilityOff } from '../partials/Icons'
import PasswordStrengthMeter from '../partials/PasswordStrengthMeter'


interface UsersEditProps {
  router: { params: { id: string }, navigate: (to: string) => any },
  auth: any,
  person: any,
  group: any,
  fetchAllGroups: (options: { limit: number, skip: number }) => Promise<any>,
  fetchPersonId: (id: number) => Promise<any>,
  updatePerson: (person: any) => Promise<any>,
}


interface UsersEditState {
  person: any,
  id: number,
  role: number,
  email: string,
  name: string,
  password: string,
  passwordShow: boolean,
  formSubmitted: boolean,
}


export class UsersEdit extends React.Component<UsersEditProps, UsersEditState> {

  state = {
    person: {},
    id: 0,
    role: 3,
    email: '',
    password: '',
    name: '',
    passwordShow: false,
    formSubmitted: false
  }

  componentDidMount() {
    this.props.fetchPersonId(parseInt(this.props.router.params.id))
    .then(() => {
      if(this.props.person.status === fetchStates.success) {
        const { person_id, role, email, name } = this.props.person.person
        this.setState({ id: person_id, role, email, name })
      }
    })
  }


  selectRole = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ role: parseInt(e.target.value) })
  }


  handleOnSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    this.setState({ formSubmitted: true });
    const { id, role, email, password, name } = this.state;
    let person = {}
    if(password === '') {  person = { id, role, email, name }  }
    if(password !== '') {  person = { id, role, email, password, name }  }
    this.props.updatePerson(person)
    .then(() => {
      this.setState({ formSubmitted: false })
      if(this.props.person.status === fetchStates.success) {
        return this.props.router.navigate('/users');
      }
    })
  }


  render() {

    const { fields, status } = this.props.person;
    const { role, email, password, name, passwordShow, formSubmitted } = this.state;
    const creatorRole = this.props.auth.role;


    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          button={false}
          title='Edit User' 
          buttonBack={true} 
          navigate={this.props.router.navigate}
          padding={['large']}
          time={false}
          animate
        >
          <form className="form" onSubmit={this.handleOnSubmit}>

            <div className="form-group">
              <label htmlFor="role">
                Role (required)
              </label>
              <select 
                name="role" 
                onChange={e => this.selectRole(e)}
                className={classnames('', { 'input-error': fields && fields.includes('role') })}
                value={role}
              >
                {[1,2,3].includes(creatorRole) && <option value={3}>User</option>}
                {[1,2].includes(creatorRole) && <option value={2}>Manager</option>}
                {[1].includes(creatorRole) && <option value={1}>Administrator</option>}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="email">
                Email (required)
              </label>
              <input 
                type="text" 
                name="email"
                id="email" 
                value={ email }
                onChange={e => this.setState({ email: e.target.value })}
                className={classnames('', { 'input-error': fields && fields.includes('email') })}
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">
                Password
                <span className="password-reveal" onClick={e => this.setState({ passwordShow: !passwordShow })}>
                  { passwordShow === false ? (
                    <IconVisibility password size={18} />
                  ) : (
                    <IconVisibilityOff password size={18} />
                  )}
                </span>
              </label>
              <input 
                type={ passwordShow === false ? 'password' : 'text' } 
                name="password"
                id="password" 
                value={ password }
                onChange={e => this.setState({ password: e.target.value })}
                className={classnames('', { 'input-error': fields && fields.includes('password') })}
              />
              <PasswordStrengthMeter password={password} />
            </div>

            <div className="form-group">
              <label htmlFor="name">
                Name
              </label>
              <input 
                type="text" 
                name="name"
                id="name" 
                value={ name }
                onChange={e => this.setState({ name: e.target.value })}
                className={classnames('', { 'input-error': fields && fields.includes('name') })}
              />
            </div>

            <div className="form-group">
              <input 
                type="submit" 
                value="Update user" 
                className="btn btn--primary card__footer--btn-left"
                disabled={ formSubmitted ? true : false }
              />
            </div>
          </form>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ person, auth }: RootState) => ({ person, auth }),
  { fetchPersonId, updatePerson }
)(UsersEdit)))
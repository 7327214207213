import { AUTH } from '../types'
import fetchStates from '../types/fetchStates'

interface AuthState {
  status: string,
  authenticated: boolean, 
  billing_enabled: boolean,
  google_maps_api_key: string,
}


const DEFAULT_AUTH: AuthState = { 
  status: '',
  authenticated: false, 
  billing_enabled: false,
  google_maps_api_key: '',
}


const auth = (state = DEFAULT_AUTH, action: any) => {
  switch(action.type) {
    case AUTH.FETCH:
      return { 
        ...state, 
        status: fetchStates.fetching,
        msg: [],
      };
    case AUTH.FETCH_ERROR:
      return { 
        ...state, 
        status: fetchStates.error,  
        fields: action.errors && action.errors.map((item: {param: string}) => item.param !== undefined && item.param.toString()),
        authenticated: false,
        msg: action.errors,
      };
    case AUTH.FETCH_LOGIN_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        authenticated: true,
        person_id: action.person_id,
        email: action.email,
        name: action.name,
        role: action.role,
        authorized: action.authorized,
        zoom: action.zoom,
        lat: action.lat,
        lng: action.lng,
        currency: action.currency,
        language: action.language,
        timezone: action.timezone,
        email_notifications: action.email_notifications,
        selected_vehicles: action.selected_vehicles,
        tables_options: action.tables_options,
        google_maps_api_key: action.google_maps_api_key,
        billing_enabled: action.billing_enabled,
        billing_paypal: action.billing_paypal,
        billing_stripe: action.billing_stripe,
        billing_proforma: action.billing_proforma,
        billing_selfmanaged: action.billing_selfmanaged,
        payment_per_vehicle: action.payment_per_vehicle,
        reports_daily: action.reports_daily
      };
    case AUTH.FETCH_AUTHENTICATED_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        authenticated: action.authenticated === undefined ? false : action.authenticated,
        person_id: action.person_id,
        email: action.email,
        name: action.name,
        role: action.role,
        authorized: action.authorized,
        zoom: action.zoom,
        lat: action.lat,
        lng: action.lng,
        currency: action.currency,
        language: action.language,
        timezone: action.timezone,
        email_notifications: action.email_notifications,
        selected_vehicles: action.selected_vehicles,
        tables_options: action.tables_options,
        google_maps_api_key: action.google_maps_api_key,
        billing_enabled: action.billing_enabled,
        billing_paypal: action.billing_paypal,
        billing_stripe: action.billing_stripe,
        billing_proforma: action.billing_proforma,
        billing_selfmanaged: action.billing_selfmanaged,
        payment_per_vehicle: action.payment_per_vehicle,
        reports_daily: action.reports_daily
      };
    case AUTH.FETCH_LOGOUT_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        authenticated: false,
        msg: action.success,
        person_id: '',
        email: '',
        name: '',
        role: '',
        authorized: '',
        zoom: '',
        lat: '',
        lng: '',
        currency: '',
        language: '',
        timezone: '',
        email_notifications: '',
        selected_vehicles: [],
        tables_options: {},
        google_maps_api_key: '',
        billing_enabled: '',
        billing_paypal: '',
        billing_stripe: '',
        billing_proforma: '',
        billing_selfmanaged: '',
        payment_per_vehicle: '',
        reports_daily: []
      };
    case AUTH.FETCH_LOGOUTALL_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        authenticated: false,
        msg: action.success,
        person_id: '',
        email: '',
        name: '',
        role: '',
        authorized: '',
        zoom: '',
        lat: '',
        lng: '',
        currency: '',
        language: '',
        timezone: '',
        email_notifications: '',
        selected_vehicles: [],
        tables_options: {},
        google_maps_api_key: '',
        billing_enabled: '',
        billing_paypal: '',
        billing_stripe: '',
        billing_proforma: '',
        billing_selfmanaged: '',
        payment_per_vehicle: '',
        reports_daily: []
      };
    case AUTH.FETCH_FORGOT_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
      };
    case AUTH.FETCH_CHECK_SUCCESS:
      return {
        ...state,
        token: action.token,
        status: fetchStates.success,
        msg: action.success,
      };
    case AUTH.FETCH_RESET_SUCCESS:
      return {
        ...state,
        fields: [],
        status: fetchStates.success,
        msg: action.success,
      };
    case AUTH.RESET:
      return {
        state: []
      };
    default:
      return state;
  }
}


export default auth
import { Routes, Route } from 'react-router-dom'
// import HomePage from './components/pages/HomePage'
import NotFoundPage from './components/pages/NotFound'
import AuthLoginPage from './components/pages/AuthLogin'
import AuthForgotPasswordPage from './components/pages/AuthForgotPassword'
import AuthResetPasswordPage from './components/pages/AuthResetPassword'
import AuthSupportPage from './components/pages/AuthSupport'
import SettingsPage from './components/pages/Settings'
import UsersPage from './components/pages/Users'
import UsersAddPage from './components/pages/UsersAdd'
import UsersEditPage from './components/pages/UsersEdit'
import UsersDeletePage from './components/pages/UsersDelete'
import InvoicesUnpaidPage from './components/pages/InvoicesUnpaid'
import InvoicesPaidPage from './components/pages/InvoicesPaid'
import InvoicePage from './components/pages/Invoice'
import InvoiceEditPage from './components/pages/InvoicesEdit'


const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<InvoicesUnpaidPage />} />
    <Route path="/invoices/paid" element={<InvoicesPaidPage />} />
    <Route path="/invoices/:id" element={<InvoicePage />} />
    <Route path="/invoices/edit/:id" element={<InvoiceEditPage />} />
    <Route path="/signin" element={<AuthLoginPage />} />
    <Route path="/forgot-password" element={<AuthForgotPasswordPage />} />
    <Route path="/reset-password/:token" element={<AuthResetPasswordPage />} />
    <Route path="/settings" element={<SettingsPage />} />
    <Route path="/users" element={<UsersPage />} />
    <Route path="/users/add" element={<UsersAddPage />} />
    <Route path="/users/edit/:id" element={<UsersEditPage />} />
    <Route path="/users/delete/:id" element={<UsersDeletePage />} />
    <Route path="/support" element={<AuthSupportPage />} />
    <Route path="*" element={<NotFoundPage />} />
  </Routes>
)


export default AppRoutes
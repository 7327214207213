import { PERSON } from '../types'
import fetchStates from '../types/fetchStates'


interface PersonState {
  people : [],
  peopleCount: number,
  person: {},
}


const DEFAULT_PERSON: PersonState = {
  people : [],
  peopleCount: 0,
  person: {}
}


const person = (state = DEFAULT_PERSON, action:any) => {
  switch(action.type) {
    case PERSON.FETCH:
      return { 
        ...state,
        status: fetchStates.fetching,
        msg: '',
        fields: []
      };
    case PERSON.FETCH_ERROR:
      return { 
        ...state, 
        status: fetchStates.error, 
        msg: action.errors, 
        fields: action.errors && action.errors.map((item: {param: string}) => item.param !== undefined && item.param.toString())
      };
    case PERSON.FETCH_ALL_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        people: action.people,
        peopleCount: parseInt(action.peopleCount, 10),
      };
    case PERSON.FETCH_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        person: action.person,
        fields: action.errors && action.errors.map((item: {param: string}) => item.param.toString())
      };
    case PERSON.FETCH_DELETE_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
      };
    case PERSON.FETCH_ADD_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
      };
    case PERSON.FETCH_UPDATE_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
        fields: action.errors && action.errors.map((item: {param: string}) => item.param.toString())
      };
    case PERSON.FETCH_SETTINGS_PASSWORD_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
        fields: action.errors && action.errors.map((item: {param: string}) => item.param.toString())
      };
    case PERSON.RESET:
      return {
        state: []
      };
    default:
      return state;
  }
}


export default person
import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { login } from '../../actions/auth'
import TemplateAuth from '../templates/TemplateAuth'
import KeepMeSigned from '../partials/KeepMeSigned'
import { Link } from 'react-router-dom'
import { IconVisibility, IconVisibilityOff } from '../partials/Icons'
import classnames from 'classnames'
import { Navigate } from 'react-router-dom'


interface AuthLoginProps {
  auth: {status: string, fields: Array<string>, authenticated: boolean},
  login: (options: { email: string, password: string, keepMeSigned: boolean }) => Promise<any>,
}


interface AuthLoginState {
  formSubmitted: boolean,
  password: string,
  email: string,
  passwordShow: boolean,
  keepMeSigned: boolean,
}


export class AuthLogin extends React.Component<AuthLoginProps, AuthLoginState> {


  state = {
    formSubmitted: false,
    password: '',
    email: '',
    passwordShow: false,
    keepMeSigned: false
  }


  handleOnSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    this.setState({ formSubmitted: true });
    const { email, password, keepMeSigned } = this.state;
    this.props.login({ email, password, keepMeSigned })
    .then(() => {
      this.setState({ formSubmitted: false });
    });
  }


  handleKeepMeSigned = () => {
    this.setState({ keepMeSigned: !this.state.keepMeSigned })
  }


  render() {

    const { fields, status, authenticated } = this.props.auth;
    const { formSubmitted, password, email, passwordShow, keepMeSigned } = this.state;


    return authenticated ? <Navigate to="/" /> : (
      <TemplateAuth>
        <div className="auth-form">
          <h1 className="auth-form__title">Login</h1>
          <form className='form' method="post" action="" onSubmit={this.handleOnSubmit}>
            <div className="form-group">
              <label htmlFor='email'>Email</label>
              <input
                id='email'
                type='email'
                autoComplete='username'
                value={email}
                onChange={e => this.setState({ email: e.target.value })}
                className={classnames('', { 'input-error': fields && fields.includes('email') })}
              />
            </div>
            <div className="form-group">
              <label htmlFor='password'>
                Password
                <span className="password-reveal" onClick={e => this.setState({ passwordShow: !passwordShow })}>
                  { passwordShow === false ? (
                    <IconVisibility password size={18} />
                  ) : (
                    <IconVisibilityOff password size={18} />
                  )}
                </span>
              </label>
              <input
                id='password'
                type={ passwordShow === false ? 'password' : 'text' } 
                autoComplete='current-password'
                value={password}
                className={classnames('', { 'input-error': fields && fields.includes('password') })}
                onChange={e => this.setState({ password: e.target.value })}
              />
            </div>

            <div className="form-group">
              <input 
                type='submit' 
                className='btn btn--primary btn--large' 
                value="Log in" 
                disabled={formSubmitted && status === 'fetching' ? true : false}
              />
            </div>

            <KeepMeSigned keepMeSigned={keepMeSigned} handleKeepMeSigned={this.handleKeepMeSigned} />

          </form>
          <div className="auth-form__footer">
            <Link className="form-footer__link" to="/forgot-password">
              Forgot password?
            </Link>
          </div>
        </div>
      </TemplateAuth>
    )
  }
}


export default connect(
  ({ auth }: RootState) => ({ auth }),
  { login }
)(AuthLogin)
import React from 'react'
import TemplateAuth from '../templates/TemplateAuth'
import { Link } from 'react-router-dom'
import { IconBack } from '../partials/Icons'


const AuthSupport: React.FC = () => {
  return (
    <TemplateAuth>
      <div className="auth-form">
        <div className="auth-form__header">
          <Link to="/" className='btn--circular'>
            <IconBack color="#ffffff" />
          </Link>
        </div>
        <h1 className="auth-form__title">Support</h1>
        <div className="csection csection__large">
          <h3 className="csection-title">Direct contact</h3>
          App related issues:
          <ul>
            <li>Email: <a href="mailto:michal.balazi@ac.com.pl">michal.balazi@ac.com.pl</a></li>
          </ul>
        </div>

        <div className="csection csection__large">
          <h3 className="csection-title">Address</h3>
          <ul>
            <li>AC S.A.</li>
            <li>42 Pulku Piechoty 50</li>
            <li>15-181 Bialystok</li>
          </ul>
        </div>
      </div>
    </TemplateAuth>
  )
}


export default AuthSupport
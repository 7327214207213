import { Link } from 'react-router-dom'
import { VERSION } from '../../config'


const Footer: React.FC = () => {
  return (
    <footer className='footer'>
      <div className='footer__copyright'>
        Copyright {new Date().getFullYear()} - AC S.A. &nbsp; | &nbsp; <Link to="/support">Support</Link> &nbsp; | &nbsp; v{VERSION}
      </div>
      <div className='footer__address'>
        <a href="https://www.google.com/maps/place/AC+S.A./@53.1418013,23.2114205,17z/data=!3m1!4b1!4m5!3m4!1s0x471ffe92e51c81b5:0x53f2cf1f349f2f05!8m2!3d53.1418013!4d23.2136145">AC S.A., ul. 42 Pułku Piechoty 50, 15-181 Białystok</a>, <a href="tel:+48857438117">Tel: + 48 85 743 81 17</a>
      </div>
    </footer>
  )
}


export default Footer

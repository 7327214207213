import { INVOICE } from '../types'
import fetchStates from '../types/fetchStates'


interface InvoiceState {
  unpaid: [],
  unpaidCount: number,
  paid: [],
  paidCount: number,
  invoice: any
}


const DEFAULT_INVOICE: InvoiceState = {
  unpaid: [],
  unpaidCount: 0,
  paid: [],
  paidCount: 0,
  invoice: {}
}


const invoice = (state = DEFAULT_INVOICE, action: any) => {
  switch(action.type) {
    case INVOICE.FETCH:
      return { 
        ...state, 
        status: fetchStates.fetching,
        msg: '',
        fields: []
      };
    case INVOICE.FETCH_ERROR:
      return { 
        ...state, 
        status: fetchStates.error, 
        msg: action.errors, 
        fields: action.errors && action.errors.map((item: {param: string}) => item.param !== undefined && item.param.toString())
      };
    case INVOICE.FETCH_UNPAID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        unpaid: action.unpaid,
        unpaidCount: parseInt(action.unpaidCount, 10),
      };
    case INVOICE.FETCH_PAID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        paid: action.paid,
        paidCount: parseInt(action.paidCount, 10),
      };
    case INVOICE.FETCH_APPROVE_INVOICE_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.message,
      }
    case INVOICE.FETCH_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        invoice: action.invoice,
      }
    case INVOICE.FETCH_UPDATE_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
        fields: action.errors && action.errors.map((item: {param: string}) => item.param.toString())
      };
    case INVOICE.RESET:
      return {
        state: []
      };
    default:
      return state;
  }
}


export default invoice
const AUTH = {
  FETCH: 'AUTH_FETCH',
  FETCH_ERROR: 'AUTH_FETCH_ERROR',
  FETCH_LOGIN_SUCCESS: 'AUTH_FETCH_LOGIN_SUCCESS',
  FETCH_AUTHENTICATED_SUCCESS: 'AUTH_FETCH_AUTHENTICATED_SUCCESS',
  FETCH_LOGOUT_SUCCESS: 'AUTH_FETCH_LOGOUT_SUCCESS',
  FETCH_LOGOUTALL_SUCCESS: 'AUTH_FETCH_LOGOUTALL_SUCCESS',
  FETCH_FORGOT_SUCCESS: 'AUTH_FETCH_FORGOT_SUCCESS',
  FETCH_CHECK_SUCCESS: 'AUTH_FETCH_CHECK_SUCCESS',
  FETCH_RESET_SUCCESS: 'AUTH_FETCH_RESET_SUCCESS',
  RESET: 'AUTH_RESET'
}


const PERSON = {
  FETCH: 'PERSON_FETCH',
  FETCH_ERROR: 'PERSON_FETCH_ERROR',
  FETCH_ALL_SUCCESS: 'PERSON_FETCH_ALL_SUCCESS',
  FETCH_ID_SUCCESS: 'PERSON_FETCH_ID_SUCCESS',
  FETCH_DELETE_SUCCESS: 'PERSON_FETCH_DELETE_SUCCESS',
  FETCH_ADD_SUCCESS: 'PERSON_FETCH_ADD_SUCCESS',
  FETCH_UPDATE_SUCCESS: 'PERSON_FETCH_UPDATE_SUCCESS',
  FETCH_SETTINGS_PASSWORD_SUCCESS: 'PERSON_FETCH_SETTINGS_PASSWORD_SUCCESS',
  RESET: 'PERSON_RESET'
}


const INVOICE = {
  FETCH: 'INVOICE_FETCH',
  FETCH_ERROR: 'INVOICE_FETCH_ERROR',
  FETCH_UNPAID_SUCCESS: 'INVOICE_FETCH_UNPAID_SUCCESS',
  FETCH_PAID_SUCCESS: 'INVOICE_FETCH_ALL_SUCCESS',
  FETCH_APPROVE_INVOICE_SUCCESS: 'INVOICE_FETCH_APPROVE_INVOICE_SUCCESS',
  FETCH_ID_SUCCESS: 'INVOICE_FETCH_ID_SUCCESS',
  FETCH_UPDATE_SUCCESS: 'INVOICE_FETCH_UPDATE_SUCCESS',
  RESET: 'INVOICE_RESET',
}


const ACCOUNT = {
  FETCH: 'ACCOUNT_FETCH',
  FETCH_ERROR: 'ACCOUNT_FETCH_ERROR',
  FETCH_ALL_SUCCESS: 'ACCOUNT_FETCH_ALL_SUCCESS',
  RESET: 'ACCOUNT_RESET',
}


export { AUTH, PERSON, INVOICE, ACCOUNT }
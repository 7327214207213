import { NavLink } from 'react-router-dom'
import { IconArrowRight } from '../Icons'
import classnames from 'classnames'
import { useLocation } from 'react-router-dom'


export const TabSettings:React.FC = () => {

  const location = useLocation()

  return (
    <nav className="tabnav-wrapper">
      <NavLink to={{ pathname: '/settings' }} className={classnames('tabnav-tab', { 'tabnav-tab--active': location.pathname === '/settings' })}>
        Settings
        <span className="tabnav-tab__arrow"><IconArrowRight color="#ffffff" /></span>
      </NavLink>
    </nav>
  )
}


export default TabSettings
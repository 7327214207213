import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { fetchInvoiceById } from '../../actions/invoice'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import { TIMEZONE, ADDRESS_LINE1, ADDRESS_LINE2, ADDRESS_LINE3, ADDRESS_LINE4, ADDRESS_LINE5, NIP, REGON } from '../../config'
import { LogoStelematicNew } from '../partials/Logos'
import createInvoicePDF from '../../helpers/createInvoicePDF'
import moment from 'moment-timezone'
import fetchStates from '../../types/fetchStates'
import Preloader from '../partials/Preloader'


interface BillingStatementsProps {
  router: { params: { id: string }, navigate: (to: string) => any },
  invoice: any,
  auth: any,
  fetchInvoiceById: (id: number) => Promise<any>,
  fetchAccountSettingsByInvoiceId: (id: number) => Promise<any>,
}


interface BillingStatementsState {
  timezone: string,
  account_id: string,
  invoice_id: string,
  invoice_number: string,
  temp_number: string,
  invoice_status: number,
  created: string,
  payment_method: string,
  amount: string,
  added_time: string,
  currency: string,
  vehicles_number: string,
  company_name: string,
  street_name: string,
  city: string,
  country: string,
  postcode: string,
  vat: string,
}


export class BillingStatements extends React.Component<BillingStatementsProps, BillingStatementsState> {

  state = {
    timezone: TIMEZONE,
    account_id: '',
    invoice_id: '',
    invoice_number: '',
    temp_number: '',
    invoice_status: 0,
    created: '',
    payment_method: '',
    amount: '',
    added_time: '',
    currency: '',
    vehicles_number: '',
    company_name: '',
    street_name: '',
    city: '',
    country: '',
    postcode: '',
    vat: '',
  }


  componentDidMount() {    
    this.props.fetchInvoiceById(parseInt(this.props.router.params.id))
    .then(() => {
      if(this.props.invoice.status === fetchStates.success) {
        const { invoice_id, invoice_number, temp_number, status, created, payment_method, amount, currency, vehicles_number, added_time, account_id, company_name, street_name, city, postcode, country, vat } = this.props.invoice.invoice
        this.setState({
          invoice_id: invoice_id !== null ? invoice_id : '',
          invoice_number: invoice_number !== null ? invoice_number: '',
          temp_number: temp_number !== null ? temp_number: '',
          invoice_status: status !== null ? status : 0,
          created: created !== null ? created: '',
          payment_method: payment_method !== null ? payment_method: '',
          amount: amount !== null ? amount: '',
          currency: currency !== null ? currency: '',
          vehicles_number: vehicles_number !== null ? vehicles_number: '',
          added_time: added_time !== null ? added_time : '',
          account_id: account_id !== null ? account_id : '',
          company_name: company_name !== null ? company_name : '',
          street_name: street_name !== null ? street_name : '',
          city: city !== null ? city : '',
          postcode: postcode !== null ? postcode : '',
          country: country !== null ? country : '',
          vat: vat !== null ? vat : '',
        })
      }
    })
  }


  handleDownloadInvoicePdf = () => {
    const { invoice_number, temp_number, created, payment_method, invoice_status, amount, currency, timezone, vehicles_number, company_name, street_name, city, country, postcode, vat, added_time } = this.state
    const pdfData = { invoice_number, temp_number, created, payment_method, invoice_status, amount, currency, timezone, vehicles_number, company_name, street_name, city, country, postcode, vat, added_time }
    createInvoicePDF(pdfData)
  }


  render() {

    const { invoice_number, temp_number, created, payment_method, invoice_status, amount, currency, timezone, vehicles_number, company_name, street_name, city, country, postcode, vat, added_time } = this.state
    const { status } = this.props.invoice

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          button={false}
          title="Invoice"
          buttonBack={true} 
          navigate={this.props.router.navigate}
          time={false}
          padding={['large']}
          tabnav='billing'
          tabnavId={0}
          animate
        >
          <div className="invoice">
            <div className="invoice__header">
              <div className="invoice__toolbar">
                <div className="invoice__logo">
                  <LogoStelematicNew color="#000000" size={40} /><h1>STAGTELEMATIC</h1>
                </div>
                <div className="invoice__button">
                  <button onClick={() => this.handleDownloadInvoicePdf()} className="btn btn--primary">Download PDF</button>
                </div>
              </div>
              <div className="invoice__from">
                <div className="invoice-from__address">
                  <h3>FROM</h3>
                  <ul>
                    <li><strong>{ ADDRESS_LINE1 }</strong></li>
                    <li>{ ADDRESS_LINE2 }</li>
                    <li>{ ADDRESS_LINE3 }</li>
                    <li>{ ADDRESS_LINE4 }</li>
                    <li>{ ADDRESS_LINE5 }</li>
                    <li>REGON: { REGON }</li>
                    <li>NIP: { NIP }</li>
                  </ul>
                </div>
                <div className="invoice-from__details">
                  <h3>DETAILS</h3>
                  <ul>
                    <li><strong>Temporary invoice number:</strong> {temp_number}</li>
                    <li><strong>Invoice number:</strong> {invoice_number}</li>
                    <li><strong>Invoice date:</strong>	{moment(created).tz(timezone).format('DD/MM/YYYY')}</li>
                    <li><strong>Invoice status:</strong> {invoice_status === 1 && 'Paid'} {invoice_status === 2 && 'Processing...'} {invoice_status === 3 && 'Unpaid'}</li>
                  </ul>
                </div>
              </div>
              <div className="invoice__to">
                <div className="invoice-to__address">
                  <h3>BILL TO</h3>
                  <ul>
                    <li><strong>{ company_name }</strong></li>
                    <li>{ street_name }</li>
                    <li>{ city } { postcode }</li>
                    <li>{ country }</li>
                    <li>{ vat }</li>
                  </ul>
                </div>
                <div className="invoice-to__details">
                  <h3>PAYMENT METHOD</h3>
                  <ul>
                    <li>{payment_method}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="invoice__body">
              <div className="invoice__items">
                <table>
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Number</th>
                      <th>Time range (months)</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Vehicle standard</td>
                      <td>{vehicles_number}</td>
                      <td>{added_time}</td>
                      <td>{currency} {amount}</td>
                    </tr>
                  </tbody>
                </table>

              </div>
            </div>
            <div className="invoice__footer">
              <div className="invoice__totals">
                <ul>
                  <li><strong>Subtotal</strong>	{currency} {amount}</li>
                  <li><strong>Total</strong>	{currency} {amount}</li>
                  <li><strong>Paid</strong>	{currency} {amount}</li>
                </ul>
              </div>
            </div>
          </div>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ invoice, auth }: RootState) => ({ invoice, auth }),
  { fetchInvoiceById }
)(BillingStatements)))
import React from 'react'
import AppRoutes from './AppRoutes'
import { connect } from 'react-redux'
import { checkAuth } from './actions/auth'
import { RootState } from './reducers'


interface StagTelematicAppProps {
  auth: { status: string, authenticated: boolean, billing_enabled: boolean, google_maps_api_key: string }
  checkAuth: () => Promise<void>,
}


interface StagTelematicAppState {
  dataLoaded: boolean,
}


export class StagTelematicApp extends React.Component<StagTelematicAppProps, StagTelematicAppState> {


  state = {
    dataLoaded: false
  }


  componentDidMount(){
    this.props.checkAuth()
    .then(() => {
      this.setState({ dataLoaded: true })
    })
  }


  renderApplication = (auth: {authenticated: boolean, google_maps_api_key: string}) => {
    return <div className={auth.authenticated ? 'page' : 'auth'}>
              <AppRoutes />
            </div>
  }


  render() {
    const { auth } = this.props
    const { dataLoaded } = this.state
    if(dataLoaded) {
      return this.renderApplication(auth)
    }
    return null
  }


}


export default connect(
  ({ auth }: RootState) => ({ auth }),
  { checkAuth }
)(StagTelematicApp)
import { ACCOUNT } from '../types'
import fetchStates from '../types/fetchStates'


interface InvoiceState {
  unpaid: [],
  unpaidCount: number,
  paid: [],
  paidCount: number,
  invoice: any
}


const DEFAULT_INVOICE: InvoiceState = {
  unpaid: [],
  unpaidCount: 0,
  paid: [],
  paidCount: 0,
  invoice: {}
}


const invoice = (state = DEFAULT_INVOICE, action: any) => {
  switch(action.type) {
    case ACCOUNT.FETCH:
      return { 
        ...state, 
        status: fetchStates.fetching,
        msg: '',
        fields: []
      };
    case ACCOUNT.FETCH_ERROR:
      return { 
        ...state, 
        status: fetchStates.error, 
        msg: action.errors, 
        fields: action.errors && action.errors.map((item: {param: string}) => item.param !== undefined && item.param.toString())
      };
    case ACCOUNT.FETCH_ALL_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        accounts: action.accounts,
      };
    case ACCOUNT.RESET:
      return {
        state: []
      };
    default:
      return state;
  }
}


export default invoice
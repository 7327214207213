import { Store } from 'react-notifications-component'


export const error = (msg: number) => {
  Store.addNotification({
    message: msg,
    type: "danger",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true
    }
  })
}

import { Provider } from 'react-redux'
import initStore from './store'
import StagTelematicAccountsApp from './StagTelematicAccountsApp'
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'


const store = initStore()

function App() {
  return (
    <Provider store={store}>
      <ReactNotifications />
      <StagTelematicAccountsApp />
    </Provider>
  )
}


export default App
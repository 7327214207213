import TabSettings from './tabnavs/TabSettings'
import TabInvoices from './tabnavs/TabInvoices'


interface TabNavsProps {
  tabnav: string, 
  tabnavId: number,
}


const TabNavs: React.FC<TabNavsProps> = ({ tabnav, tabnavId }) => {
  return (
    <>
      {tabnav && tabnav === 'settings' && (
        <TabSettings />
      )}

      {tabnav && tabnav === 'invoices' && (
        <TabInvoices />
      )}
    </>
  )
}


export default TabNavs
import React from 'react';
import { RootState } from '../../reducers'
import { connect } from 'react-redux';
import { logout, logoutAll } from '../../actions/auth';
import { NavLink, Link, redirect } from 'react-router-dom';
import { IconBilling, IconPower, IconDropdown, IconSettings, IconUsers } from './Icons';
import { LogoStelematicNew, LogoStelematicFullNew} from './Logos';
import MenuButton from './MenuButton';


interface ToolBarProps {
  auth: { name: string, email: string, role: number, billing_enabled: boolean },
  logout: () => void,
  logoutAll: () => void,
}


interface ToolBarState {
  dropdownActive : boolean,
  sidenavActive: boolean,
  isOpen: boolean
}


export class ToolBar extends React.Component<ToolBarProps, ToolBarState> {

  state = {
    dropdownActive : false,
    sidenavActive: false,
    isOpen: false
  }


  getRole = (role: number) => {
    if(role === 1) return 'Administrator';
    if(role === 2) return 'Manager';
    if(role === 3) return 'Accounts';
    if(role === 4) return 'Technician';
  }


  toggleDropdown = () => {
    this.setState({
      dropdownActive: !this.state.dropdownActive
    });
  }


  toggleSidenav = () => {
    this.setState({
      sidenavActive: !this.state.sidenavActive,
      isOpen : !this.state.isOpen
    });
  }


  logout = () => {
    this.props.logout();
    redirect('/');
  }


  logoutAll = () => {
    this.props.logoutAll();
    redirect('/');
  }


  render() {

    const { dropdownActive, sidenavActive, isOpen } = this.state
    const { name, email, role  } = this.props.auth;

    return (
      <>
        <div className={ sidenavActive ? 'sidenav sidenav--visible animation--fade-in' : 'sidenav'}>
          <nav className="sidenav__main-nav">

            <NavLink to="/" className="sidenav__nav-item" >
              <IconBilling color="#ffffff" /> 
              <span className="sidenav__nav-title">
              Invoices
              </span>
            </NavLink>

            <NavLink to="/" className="sidenav__nav-item" >
              <IconUsers color="#ffffff" />
              <span className="sidenav__nav-title">
              Invoices
              </span>
            </NavLink>

          </nav>
        </div>

        <div className="toolbar">
          <div className="toolbar__logo-section">
            <Link to="/">
              <div className="toolbar__logo">
                <LogoStelematicNew color="#ffffff" size={40} />
              </div>
              <div className="toolbar__logo-title">
                <LogoStelematicFullNew color="#ffffff" size={160} />
              </div>
            </Link>
          </div>

          <div className="toolbar__spacer"></div>
          
          <span className="toolbar__mobile-nav" onClick={this.toggleSidenav}>
            <span className="mobile-nav__hint">MENU</span>
            <MenuButton
              isOpen={isOpen}
              strokeWidth={4}
              color="#ffffff"
              transition={{ ease: "easeOut", duration: 0.2 }}
              width={40}
              height={24}
              className="toolbar__mobile-nav-button"
            />
          </span>

          <div className="toolbar__account-nav">
            <div className="dropdown dropdown--toolbar" onClick={this.toggleDropdown}>
              <div className="toolbar__account-nav-link dropdown__trigger">
                <span className="toolbar__account-nav--account-icon toolbar__logo-client"></span>
                <span className="toolbar__account-nav--username">{name ? name : this.getRole(role) } </span>
                <span className="toolbar__account-nav--email">{email}</span>
                <IconDropdown dropdown />
              </div>
              <ul className={ dropdownActive ? 'dropdown--visible' : undefined}>
                <li className="dropdown__item">
                  <Link to="/settings">
                    <IconSettings />
                    <span className="dropdown__title">
                      Settings
                    </span>
                  </Link>
                </li>
                <li className="dropdown__item">
                  <Link to="/" onClick={() => this.logout()}>
                    <IconPower />
                    <span className="dropdown__title">
                      Log out
                    </span>
                  </Link>
                </li>
                <li className="dropdown__item">
                  <Link to="/" onClick={() => this.logoutAll()}>
                    <IconPower />
                    <span className="dropdown__title">
                      Log out everywhere
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </>
    )
  }
}


export default connect(
  ({ auth }: RootState) => ({ auth }),
  { logout, logoutAll }
)(ToolBar)
import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { fetchAccountancyAllPaidInvoices } from '../../actions/invoice'
import { fetchAllAccounts } from '../../actions/account'
import TemplatePage from '../templates/TemplatePage'
import TableHeader from '../partials/TableHeader'
import TableFooter from '../partials/TableFooter'
import Card from '../partials/Card'
import { Link } from 'react-router-dom'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import { TIMEZONE } from '../../config'
import queryString from 'query-string'
import fetchStates from '../../types/fetchStates'
import { IconVisibility, IconEdit } from '../partials/Icons'
import moment from 'moment-timezone'
import { Tooltip } from 'react-tooltip'
import Preloader from '../partials/Preloader'
import DatePicker from 'react-datepicker'


interface InvoicesPaidProps {
  router: {location: {pathname: string, search: string}, navigate: (to: string) => any},
  invoice: any,
  account: any,
  fetchAccountancyAllPaidInvoices: (options: {limit: number, skip: number, search: string, from: string, to: string, account_id: string}) => Promise<any>,
  fetchAllAccounts: () => Promise<any>
}


interface InvoicesPaidState {
  paid: any,
  paidCount: number,
  currentPage: number,
  perPage: number,
  search: string,
  accounts: Array<any>,
  account_id: string,
  filterDateFrom: any,
  filterDateTo: any,
  todayDate: any,
  defaultToDate: any,
  dataLoaded: boolean,
  dataAccountsLoaded: boolean,
  timezone: string,
}


export class InvoicesPaid extends React.Component<InvoicesPaidProps, InvoicesPaidState> {

  state = {
    paid: [], 
    paidCount: 0, 
    currentPage: 0,
    perPage: 10,
    search: '',
    accounts: [],
    account_id: 'all',
    filterDateFrom:  moment().subtract(1, 'year').toDate(),
    filterDateTo: moment().toDate(),
    todayDate: moment().toDate(),
    defaultToDate: moment().toDate(),
    dataLoaded: false,
    dataAccountsLoaded: false,
    timezone: TIMEZONE
  }


  componentDidMount() {
    this.fetchAllAccounts();
    // timezone
    this.setState({ timezone: TIMEZONE })
    // set the defaults
    let { currentPage, perPage, search, filterDateFrom, filterDateTo, account_id } = this.state
    // get the defaults from the URL query if it exists
    const parsed = queryString.parse(this.props.router.location.search)
    if(parsed.page !== null && parsed.page !== undefined) {
      currentPage = parseInt(parsed.page.toString())
    }
    if(parsed.perPage !== null && parsed.perPage !== undefined) {
      if(parsed && parseInt(parsed.perPage.toString()) < 51) {
        perPage = parseInt(parsed.perPage.toString())
      } else {
        this.props.router.navigate(`/invoices/paid?page=${currentPage}&perPage=${perPage}&from=${moment(filterDateFrom).format('YYYY-MM-DD')}&to=${moment(filterDateTo).format('YYYY-MM-DD')}&account_id=${account_id}`)
      }
    } else {
      this.props.router.navigate(`/invoices/paid?page=${currentPage}&perPage=${perPage}&from=${moment(filterDateFrom).format('YYYY-MM-DD')}&to=${moment(filterDateTo).format('YYYY-MM-DD')}&account_id=${account_id}`)
    }
    if(parsed.search !== null && parsed.search !== undefined) {
      search = parsed.search.toString()
    }
    this.setState({ perPage, currentPage, search })
    this.reloadPage({ perPage, currentPage, search, filterDateFrom, filterDateTo, account_id });  
  }


  fetchAllAccounts = async () => {
    await this.props.fetchAllAccounts();
    if(this.props.account.status === fetchStates.success) {
      const { accounts } = this.props.account;
      if(accounts.length > 0) {
        let account_id = 'all';
        if(accounts.length === 1) {
          account_id = accounts[0].account_id;
        }     
        this.setState({ accounts, account_id, dataAccountsLoaded: true });
      } else {
        this.setState({ dataLoaded: true, dataAccountsLoaded: false });
      }
    }
  }


  handleSelectAccount = (account_id: string) => {
    this.setState({ account_id, currentPage: 0 }, () => this.reloadPage({ perPage: this.state.perPage, currentPage: 0, search: this.state.search, filterDateFrom: this.state.filterDateFrom, filterDateTo: this.state.filterDateTo, account_id }));
  }


  handleFilterFromDate = (filterDateFrom: Date) => {
    this.setState({ filterDateFrom });
    const { perPage, currentPage, search, filterDateTo, account_id } = this.state;
    this.reloadPage({ perPage, currentPage, search, filterDateFrom: moment(filterDateFrom).format('YYYY-MM-DD'), filterDateTo: moment(filterDateTo).format('YYYY-MM-DD'), account_id });
    this.props.router.navigate(`/invoices/paid?page=${currentPage}&perPage=${perPage}&from=${moment(filterDateFrom).format('YYYY-MM-DD')}&to=${moment(filterDateTo).format('YYYY-MM-DD')}&account_id=${account_id}`)
  }


  handleFilterToDate = (filterDateTo: Date) => {
    this.setState({ filterDateTo });
    const { perPage, currentPage, search, filterDateFrom, account_id } = this.state;
    this.reloadPage({ perPage, currentPage, search, filterDateFrom: moment(filterDateFrom).format('YYYY-MM-DD'), filterDateTo: moment(filterDateTo).format('YYYY-MM-DD'), account_id });
    this.props.router.navigate(`/invoices/paid?page=${currentPage}&perPage=${perPage}&from=${moment(filterDateFrom).format('YYYY-MM-DD')}&to=${moment(filterDateTo).format('YYYY-MM-DD')}&account_id=${account_id}`)
  }



  handleSearch = (search: string) => {
    this.setState({ search })
    if(search.length === 0 || search.length > 3) {
      const {  perPage, currentPage, filterDateFrom, filterDateTo, account_id } = this.state;
      this.reloadPage({ perPage, currentPage, search, filterDateFrom, filterDateTo, account_id });
    }
  }


  updatePagination = (e: React.ChangeEvent<HTMLSelectElement>): any => {
    const { search, filterDateFrom, filterDateTo, account_id } = this.state;
    this.setState({ perPage: parseInt(e.target.value), currentPage: 0, search, filterDateFrom, filterDateTo, account_id });
    this.reloadPage({ perPage: parseInt(e.target.value), currentPage: 0, search, filterDateFrom, filterDateTo, account_id });
    this.props.router.navigate(`/invoices/paid?page=0&perPage=${e.target.value}&from=${moment(filterDateFrom).format('YYYY-MM-DD')}&to=${moment(filterDateTo).format('YYYY-MM-DD')}&account_id=${account_id}`);
  }


  prevPage = (prevPage: number) => {
    this.setState({ currentPage: prevPage })
    const { perPage, search, filterDateFrom, filterDateTo, account_id } = this.state;
    this.reloadPage({ currentPage: prevPage, perPage, search, filterDateFrom, filterDateTo, account_id });
    this.props.router.navigate(`/invoices/paid?page=${prevPage}&perPage=${perPage}&from=${moment(filterDateFrom).format('YYYY-MM-DD')}&to=${moment(filterDateTo).format('YYYY-MM-DD')}&account_id=${account_id}`)
  }


  nextPage = (nextPage: number) => {
    this.setState({ currentPage: nextPage })
    const { perPage, search, filterDateFrom, filterDateTo, account_id } = this.state;
    this.reloadPage({ currentPage: nextPage, perPage, search, filterDateFrom, filterDateTo, account_id })
    this.props.router.navigate(`/invoices/paid?page=${nextPage}&perPage=${perPage}&from=${moment(filterDateFrom).format('YYYY-MM-DD')}&to=${moment(filterDateTo).format('YYYY-MM-DD')}&account_id=${account_id}`)
  }


  reloadPage = (options: { perPage: number, currentPage: number, search: string, filterDateFrom: any, filterDateTo: any, account_id: string }) => {
    const { perPage, currentPage, search, filterDateFrom, filterDateTo, account_id } = options;
    this.setState({ dataLoaded: false });
    this.props.fetchAccountancyAllPaidInvoices({ limit: perPage, skip: perPage * currentPage, search, from: moment(filterDateFrom).format('YYYY-MM-DD'), to: moment(filterDateTo).format('YYYY-MM-DD'), account_id })
    .then(() => {
      if(this.props.invoice.status === fetchStates.success) {
        const { paid, paidCount } = this.props.invoice;
        this.setState({ paid, paidCount, dataLoaded: true })
      }
    }); 
  }


  render() {

    const { status } = this.props.invoice;
    const { search, paid, paidCount, currentPage, perPage, timezone, accounts, account_id, dataLoaded, dataAccountsLoaded, filterDateFrom, filterDateTo, todayDate } = this.state;

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          button={false}
          title="Invoices"
          time={false}
          tabnav='invoices'
          tabnavId={0}
          animate
        >

          <form className="form">

          {
            dataAccountsLoaded ? (
              <div className="filter">
                <span className="filter__filter-group">
                  <span className="filter__filter-text">Filter by account</span>
                  <span className="filter_filter-select">
                    <select 
                      name="groups" 
                      onChange={e => this.handleSelectAccount(e.target.value)}
                      value={account_id}
                    >
                      <option key="0" value="all">All accounts</option>
                      { 
                        accounts && accounts.map((account: {account_id: number, company_name: string}) => <option key={account.account_id} value={account.account_id}>{account.company_name}</option>)
                      }
                    </select>
                  </span>
                </span>
                <span className="filter__filter-group">
                <span className="filter__filter-text">From</span>
                  <span className="filter_filter-select">
                    <DatePicker
                      selected={filterDateFrom}
                      selectsStart
                      onChange={this.handleFilterFromDate}
                      dateFormat='dd/MM/yyyy'
                      maxDate={filterDateTo}
                      required={true}
                    />
                  </span>
              </span>
              <span className="filter__filter-group">
                <span className="filter__filter-text">To</span>
                <span className="filter_filter-select">
                  <DatePicker
                    selected={filterDateTo}
                    selectsStart
                    onChange={this.handleFilterToDate}
                    dateFormat='dd/MM/yyyy'
                    maxDate={todayDate}
                    minDate={filterDateFrom}
                    required={true}
                  />
                </span>
              </span>

              <span className="filter__filter-group">
                <span className="filter__filter-text">Search by invoice #</span>
                <span className="filter_filter-select">
                  <input type="text" name="search" id="search" value={search} onChange={e => this.handleSearch( e.target.value)} />
                </span>
              </span>
            </div>
            ) : (
              <Preloader type="" />
            )
          }

          </form>

          <div className="table-wrapper">

            {
              dataLoaded ? (
                <table className="table">
                  <TableHeader 
                    columns={['', 'Invoice Temp Number', 'Invoice No.', 'Invoice date', 'Company', 'No of vehicles', 'Added time', 'Payment', 'Amount', 'Status']} 
                  />

                  <tbody className="table__body table__body--striped">
                    { paid && paid.map((item: {invoice_id: number, temp_number: number, invoice_number: string, created: number, vehicles_number: number, added_time: number, payment_method: string, company_name: string, amount: number, currency: string, status: number}, index: number) => {
                      return (
                      <tr key={item.invoice_id}>
                        <td className="simple">
                          <Tooltip anchorId={`tooltip-imei-delete${index}`} />
                          <span id={`tooltip-imei-delete${index}`} data-tooltip-content="Edit">
                            <Link to={`/invoices/${item.invoice_id}`}>
                              <IconVisibility task button color="#ffffff" />
                            </Link>
                          </span>
                        </td>
                        <td>
                          {item.temp_number}
                        </td>
                        <td>
                          {item.invoice_number}
                        </td>
                        <td>
                          {moment(item.created).tz(timezone).format('DD/MM/YYYY')}
                        </td>
                        <td>
                          {item.company_name}
                        </td>
                        <td>
                          {item.vehicles_number}
                        </td>
                        <td>
                          {item.added_time} month(s)
                        </td>
                        <td>
                          {item.payment_method}
                        </td>
                        <td>
                          {item.amount} {item.currency}
                        </td>
                        <td>
                          {item.status === 1 && <span className="table__highlight table__highlight--success">Paid</span>}
                          {item.status !== 1 && item.status !== 3 && <span className="table__highlight table__highlight--warning">Processing ...</span>}
                          {item.status === 3 && <span className="table__highlight table__highlight--danger">Unpaid</span>}
                        </td>
                        <td className="simple">
                          <Tooltip anchorId={`tooltip-user-edit${index}`}/>
                          <span id={`tooltip-user-edit${index}`} data-tooltip-content="Edit">
                            <Link to={`/invoices/edit/${item.invoice_id}`}>
                              <IconEdit task button color="#ffffff" />
                            </Link>
                          </span>
                        </td>
                      </tr>
                      )
                    })
                  }
                  </tbody>

                  <TableFooter 
                    button={false}
                    colSpan={11}
                    currentPage={ currentPage }
                    perPage={ perPage }
                    itemsCount={ paidCount }
                    prevPage={() => this.prevPage(currentPage - 1)}
                    nextPage={() => this.nextPage(currentPage + 1)}
                    updatePagination={this.updatePagination}
                  />
                </table>
              ) : (
                <Preloader type="" />
              )
            }
          </div>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ invoice, account }: RootState) => ({ invoice, account }),
  { fetchAccountancyAllPaidInvoices, fetchAllAccounts }
)(InvoicesPaid)))
import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { fetchPersonId, deletePersonId } from '../../actions/person'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import classnames from 'classnames'
import Preloader from '../partials/Preloader'
import { error } from '../../helpers/notifications'
import fetchStates from '../../types/fetchStates'


interface UsersDeleteProps {
  router: { params: { id: string }, navigate: (to: string) => any },
  person: any,
  fetchPersonId: (id: number) => Promise<any>,
  deletePersonId: (id: number) => Promise<any>,
}


interface UsersDeleteState {
  person: { email: string },
  email: string,
  formSubmitted: boolean,
}


export class UsersDelete extends React.Component<UsersDeleteProps, UsersDeleteState> {

  state = {
    person: { email: '' },
    email: '',
    formSubmitted: false,
  }


  componentDidMount() {
    this.props.fetchPersonId(parseInt(this.props.router.params.id))
    .then(() => {
      if(this.props.person.status === fetchStates.success) {
        this.setState({ person: this.props.person.person })
      }
    });
  }


  handleOnSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    this.setState({ formSubmitted: true })
    if(this.state.email === '') {
      this.props.person.fields = ['email']
      this.setState({ formSubmitted: false })
      return error(2335)
    }
    if(this.state.email !== this.props.person.person.email) {
      this.props.person.fields = ['email']
      this.setState({ formSubmitted: false })
      return error(2336)
    }
    if(this.state.email === this.props.person.person.email) {
      this.props.deletePersonId(this.props.person.person.person_id)
      .then(() => {
        if(this.props.person.status === fetchStates.success) {
          return this.props.router.navigate('/users');
        }
        this.setState({ formSubmitted: false })
      })
    }
  }


  render() {

    const { status, fields } = this.props.person;
    const { person, email, formSubmitted } = this.state;

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          button={false}
          title={`Delete user with email address '${person.email}'`} 
          buttonBack={true} 
          navigate={this.props.router.navigate}
          padding={['large']} 
          danger={true}
          time={false}
          animate
        >
          <p>You are about to delete user with email address <strong>{person.email}</strong>. Access to the aplication will be blocked. This action can not be reversed. Are you sure you want to proceed?</p>
          <form className="form" onSubmit={this.handleOnSubmit}>
            <div className="form-group">
              <label htmlFor="email">
                Confirm your choice by typing in the email of the user
              </label>
              <input 
                type="text" 
                name="email"
                id="email" 
                value={email}
                onChange={e => this.setState({ email: e.target.value })}
                className={classnames('', { 'input-error': fields && fields.includes('email') })}
              />
            </div>
            <div className="card__footer">
              <input 
                type="submit" 
                value="Delete user" 
                className="btn btn--primary btn--left"
                disabled={ formSubmitted ? true : false }
              />
            </div>
          </form>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ person }: RootState) => ({ person }),
  { fetchPersonId, deletePersonId }
)(UsersDelete)))
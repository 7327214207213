import React from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../reducers'
import SideBar from '../partials/SideBar'
import ToolBar from '../partials/ToolBar'
import Footer from '../partials/Footer'


interface TemplatePageProps {
  children: JSX.Element|JSX.Element[],
  auth: { authenticated: boolean, role: number },
}


export class TemplatePage extends React.Component<TemplatePageProps> {
  render() {
    return (
      <div className="template template__page">
        <ToolBar />
        <SideBar 
          auth={this.props.auth} 
        />
        <div className="main-content">
          <div className="main-content__wrapper">
            {this.props.children}
          </div>
          <Footer />
        </div>
      </div>
    )
  }
}


export default connect(
  ({ auth }: RootState) => ({ auth }),
  null
)(TemplatePage)
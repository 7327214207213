import React from 'react'


interface KeepMeSignedProps {
  keepMeSigned: boolean,
  handleKeepMeSigned: () => void,
}


const KeepMeSigned: React.FC<KeepMeSignedProps> = ({ keepMeSigned, handleKeepMeSigned }) => {
  return (
      <div className="keep-me-signed">
        <div className="form-group__checkbox form-group__checkbox--large">
          <label>
            <input 
              onChange={handleKeepMeSigned}
              checked={keepMeSigned}
              type="checkbox" 
              name="default"  
            />
            Keep me signed
          </label>
        </div>
      </div>
  )
}


export default KeepMeSigned
import { PERSON } from '../types';
import { API_ADDRESS } from '../config';
import { Store } from 'react-notifications-component';


let prevMessage = 0;

const fetchPerson = (options: { endpoint: string, options: any, SUCCESS_TYPE: string }) => (dispatch: any) => {
  dispatch({ type: PERSON.FETCH });
  return fetch(`${API_ADDRESS}/person/${options.endpoint}`, options.options)
        .then(response => response.json())
        .then(json => {
          if (json.errors !== undefined) {
            dispatch({ type: PERSON.FETCH_ERROR, ...json });
            json && json.errors && json.errors.map((item: {msg: number}) => {
              if(item.msg) {
                if(item.msg === prevMessage && json.repeat === false) {
                  return null
                } else {
                  Store.addNotification({
                    message: item.msg,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true
                    }
                  });
                }
                prevMessage = item.msg
                setTimeout(() => prevMessage = 0, 10000);
              }
              return null
            })
          } else {
            dispatch({ type: options.SUCCESS_TYPE, ...json });
            json && json.success && json.success.map((item: { msg: number }) => {
              if(item.msg) {
                
                if(item.msg === prevMessage && json.repeat === false) {
                  return null
                } else {
                  Store.addNotification({
                    message: item.msg,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true
                    }
                  });
                }
                prevMessage = item.msg
                setTimeout(() => prevMessage = 0, 10000);
              }
              return null
            })
          }
        })
        .catch(error => dispatch({
          type: PERSON.FETCH_ERROR, errors: [{ msg: error }]
        }));
}


export const fetchAllPeople = (options: { limit: number, skip: number }) => fetchPerson({
  endpoint: '?limit=' + options.limit + '&skip=' + options.skip,
  options: { credentials: 'include' },
  SUCCESS_TYPE: PERSON.FETCH_ALL_SUCCESS
});


export const fetchPersonId = (id: number) => fetchPerson({
  endpoint: `${id}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: PERSON.FETCH_ID_SUCCESS
});


export const deletePersonId = (id: number) => fetchPerson({
  endpoint: `${id}`,
  options: {
    method: 'DELETE',
    options: { credentials: 'include' },
  },
  SUCCESS_TYPE: PERSON.FETCH_DELETE_SUCCESS
});


export const addNewPerson = (person: any) => fetchPerson({
  endpoint: '',
  options: {
    method: 'POST',
    body: JSON.stringify({ ...person }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: PERSON.FETCH_ADD_SUCCESS
});


export const updatePerson = (person: any) => fetchPerson({
  endpoint: `${person.id}`,
  options: {
    method: 'PUT',
    body: JSON.stringify({ ...person }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: PERSON.FETCH_UPDATE_SUCCESS
});


export const updatePersonPasswordSettings = (options: { id: number, password_old: string, password_new: string, password_new_confirm: string }) => fetchPerson({
  endpoint: `settings/password/${options.id}`,
  options: {
    method: 'PUT',
    body: JSON.stringify({ password_old: options.password_old, password_new: options.password_new, password_new_confirm: options.password_new_confirm }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: PERSON.FETCH_SETTINGS_PASSWORD_SUCCESS
});
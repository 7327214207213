import React from 'react';

interface AuthByRoleProps {
  role: number[],
  auth: { role: number },
  children: JSX.Element,
}


export const AuthByRole: React.FC<AuthByRoleProps> = ({ role, auth, children }) => {

    if(role.includes(auth.role)) {
      return children
    }

    return null;
}

export default AuthByRole;

import { INVOICE } from '../types';
import { API_ADDRESS } from '../config';
import { Store } from 'react-notifications-component';

let prevMessage = 0;

const fetchInvoice = (options: { endpoint: string, options: any, SUCCESS_TYPE: string }) => (dispatch: any) => {
  dispatch({ type: INVOICE.FETCH });
  return fetch(`${API_ADDRESS}/invoice/${options.endpoint}`, options.options)
        .then(response => response.json())
        .then(json => {
          if (json.errors !== undefined) {
            dispatch({ type: INVOICE.FETCH_ERROR, ...json });
            json && json.errors && json.errors.map((item: {msg: number}) => {
              if(item.msg) {
                
                if(item.msg === prevMessage && json.repeat === false) {
                  return null
                } else {
                  Store.addNotification({
                    message: item.msg,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true
                    }
                  });
                }
                prevMessage = item.msg
                setTimeout(() => prevMessage = 0, 10000);
              }
              return null
            })
          } else {
            dispatch({ type: options.SUCCESS_TYPE, ...json });
            json && json.success && json.success.map((item: {msg: number}) => {
              if(item.msg) {
                
                if(item.msg === prevMessage && json.repeat === false) {
                  return null
                } else {
                  Store.addNotification({
                    message: item.msg,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true
                    }
                  });
                }
                prevMessage = item.msg
                setTimeout(() => prevMessage = 0, 10000);
              }
              return null
            })
          }
        })
        .catch(error => dispatch({
          type: INVOICE.FETCH_ERROR, errors: [{ msg: error }]
        }));
}


export const fetchAccountancyAllUnpaidInvoices = (options: { limit: number, skip: number, search: string, from: string, to: string, account_id: string }) => fetchInvoice({
  endpoint: `accountancy/unpaid/?limit=${options.limit}&skip=${options.skip}&search=${options.search}&from=${options.from}&to=${options.to}&account_id=${options.account_id}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: INVOICE.FETCH_UNPAID_SUCCESS
});


export const fetchAccountancyAllPaidInvoices = (options: { limit: number, skip: number, search: string, from: string, to: string, account_id: string }) => fetchInvoice({
  endpoint: `accountancy/paid/?limit=${options.limit}&skip=${options.skip}&search=${options.search}&from=${options.from}&to=${options.to}&account_id=${options.account_id}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: INVOICE.FETCH_PAID_SUCCESS
});


export const fetchInvoiceById = (id: number) => fetchInvoice({
  endpoint: `${id}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: INVOICE.FETCH_ID_SUCCESS
});


export const updateInvoiceById = (invoice: any) => fetchInvoice({
  endpoint: `${invoice.invoice_id}`,
  options: {
    method: 'PUT',
    body: JSON.stringify({ ...invoice }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: INVOICE.FETCH_UPDATE_SUCCESS
});


export const approveInvoice = (invoice_id:number) => fetchInvoice({
  endpoint: `approve-invoice/${invoice_id}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: INVOICE.FETCH_APPROVE_INVOICE_SUCCESS
});
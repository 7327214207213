import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { addNewPerson } from '../../actions/person'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import fetchStates from '../../types/fetchStates'
import classnames from 'classnames'
import { IconVisibility, IconVisibilityOff } from '../partials/Icons'
import PasswordStrengthMeter from '../partials/PasswordStrengthMeter'
import Switch from '../partials/Switch'
import Preloader from '../partials/Preloader'


interface UsersAddProps {
  router: { navigate: (to: string) => any },
  auth: any,
  person: any,
  addNewPerson: ( 
    person: { 
      role: number, 
      email: string, 
      password: string,
      name: string, 
      notify: boolean,
    } 
  ) => Promise<any>,
}


interface UsersAddState {
  role: number,
  email: string,
  password: string,
  passwordShow: boolean,
  name: string,
  notify: boolean,
  formSubmitted: boolean,
}


export class UsersAdd extends React.Component<UsersAddProps, UsersAddState> {

  state = {
    role: 3,
    email: '',
    password: '',
    passwordShow: false,
    name: '',
    notify: true,
    formSubmitted: false,
  }


  selectRole = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ role: parseInt(e.target.value) })
  }
  

  handleOnSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    this.setState({ formSubmitted: true })
    const {role, email, password, name, notify } = this.state
    const person = { 
      role, 
      email, 
      password,
      name, 
      notify,
    }
    this.props.addNewPerson(person)
    .then(() => {
      if(this.props.person.status === fetchStates.success) {
        this.props.router.navigate('/users')
      }
      this.setState({ formSubmitted: false })
    })
  }


  render() {

    const { fields, status } = this.props.person;
    const { email, password, name, notify, passwordShow, formSubmitted } = this.state;
    const creatorRole = this.props.auth.role;
    
    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          button={false}
          title="Add user"
          buttonBack={true} 
          navigate={this.props.router.navigate}
          padding={['large']}
          time={false}
          animate
        >
          <form className="form" onSubmit={this.handleOnSubmit}>

            <div className="form-group">
              <label htmlFor="role">
                Role (required)
              </label>
              <select 
                name="role" 
                onChange={e => this.selectRole(e)}
                className={classnames('', { 'input-error': fields && fields.includes('role') })}
              >
                {[1,2,3].includes(creatorRole) && <option value={3}>User</option>}
                {[1,2].includes(creatorRole) && <option value={2}>Manager</option>}
                {[1].includes(creatorRole) && <option value={1}>Administrator</option>}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="email">
                Email (required)
              </label>
              <input 
                type="text" 
                name="email"
                id="email" 
                value={ email }
                onChange={e => this.setState({ email: e.target.value })}
                className={classnames('', { 'input-error': fields && fields.includes('email') })}
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">
                Password (required)
                <span className="password-reveal" onClick={e => this.setState({ passwordShow: !passwordShow })}>
                  { passwordShow === false ? (
                    <IconVisibility password size={18} />
                  ) : (
                    <IconVisibilityOff password size={18} />
                  )}
                </span>
              </label>
              <input 
                type={ passwordShow === false ? 'password' : 'text' } 
                name="password"
                id="password" 
                value={ password }
                onChange={e => this.setState({ password: e.target.value })}
                className={classnames('', { 'input-error': fields && fields.includes('password') })}
              />
              <PasswordStrengthMeter password={password} />
            </div>

            <div className="form-group">
              <label htmlFor="name">
                Name
              </label>
              <input 
                type="text" 
                name="name"
                id="name" 
                value={ name }
                onChange={e => this.setState({ name: e.target.value })}
                className={classnames('', { 'input-error': fields && fields.includes('name') })}
              />
            </div>

            <div className="form-group__switch form-group__switch--large">
              <label onClick={e => this.setState({ notify: !notify })}>
                <Switch switchState={notify} />
                <span className="label-title">
                  Send email to user
                </span>
              </label>
            </div>


            <div className="form-group">
              <input 
                type="submit" 
                value="Add user" 
                className="btn btn--primary card__footer--btn-left"
                disabled={ formSubmitted ? true : false }
              />
            </div>

          </form>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ person, auth }: RootState) => ({ person, auth }),
  { addNewPerson }
)(UsersAdd)))
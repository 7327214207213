import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { fetchInvoiceById, updateInvoiceById } from '../../actions/invoice'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import fetchStates from '../../types/fetchStates'
import Preloader from '../partials/Preloader'
import classnames from 'classnames'


interface UsersEditProps {
  router: { params: { id: string }, navigate: (to: string) => any },
  invoice: any,
  fetchInvoiceById: (id: number) => Promise<any>,
  updateInvoiceById: (invoice: any) => Promise<any>,
}


interface UsersEditState {
  invoice_id: number,
  invoice_number: string,
  formSubmitted: boolean
}


export class UsersEdit extends React.Component<UsersEditProps, UsersEditState> {

  state = {
    invoice_id: 0,
    invoice_number: '',
    formSubmitted: false
  }


  componentDidMount() {
    this.props.fetchInvoiceById(parseInt(this.props.router.params.id))
    .then(() => {
      if(this.props.invoice.status === fetchStates.success) {
        const { invoice_id, invoice_number } = this.props.invoice.invoice
        this.setState({
          invoice_id: invoice_id !== null ? invoice_id: '',
          invoice_number: invoice_number !== null ? invoice_number: '',
        })
      }
    })
  }


  handleOnSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    this.setState({ formSubmitted: true });
    const { invoice_id, invoice_number } = this.state
    const invoice = { invoice_id, invoice_number }
    this.props.updateInvoiceById(invoice)
    .then(() => {
      this.setState({ formSubmitted: false });
      if(this.props.invoice.status === fetchStates.success) {
        return this.props.router.navigate('/');
      }
    })

  }


  render() {

    const { fields, status } = this.props.invoice;
    const { invoice_number, formSubmitted } = this.state;

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          button={false}
          title='Update invoice number' 
          buttonBack={true} 
          navigate={this.props.router.navigate}
          padding={['large']}
          time={false}
          animate
        >
          <form className="form" onSubmit={this.handleOnSubmit}>

            <div className="form-group">
              <label htmlFor="name">
                Invoice Number
              </label>
              <input 
                type="text" 
                name="name"
                id="name" 
                value={ invoice_number }
                onChange={e => this.setState({ invoice_number: e.target.value })}
                className={classnames('', { 'input-error': fields && fields.includes('invoice_number') })}
              />
            </div>

            <div className="form-group">
              <input 
                type="submit" 
                value="Update invoice" 
                className="btn btn--primary card__footer--btn-left"
                disabled={ formSubmitted ? true : false }
              />
            </div>

          </form>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ invoice }: RootState) => ({ invoice }),
  { fetchInvoiceById, updateInvoiceById }
)(UsersEdit)))
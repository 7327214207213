import { NavLink } from 'react-router-dom'
import { IconUsers, IconBilling } from './Icons'
import classnames from 'classnames'
import { useLocation } from 'react-router-dom'
import AuthByRole from './AuthByRole'


interface SideBarProps {
  auth: { authenticated: boolean, role: number },

}


const SideBar: React.FC<SideBarProps> = ({ auth }) => {

  const location = useLocation()

  return (auth.authenticated === true && (

    <div className="sidebar">
      <nav className="sidebar__main-nav">

        <NavLink to="/" className={classnames('sidebar__nav-item', {'sidebar__nav-item--current': location.pathname === '/'})}>
          <IconBilling color="#ffffff" /> 
          <span className="sidebar__nav-title">
            Invoices
          </span>
        </NavLink>

        <AuthByRole role={[1,2]} auth={auth}>
          <NavLink to="/users" className={classnames('sidebar__nav-item', {'sidebar__nav-item--current': location.pathname.substring(0,6) === '/users'})}>
            <IconUsers color="#ffffff" /> 
            <span className="sidebar__nav-title">
              Users
            </span>
          </NavLink>
        </AuthByRole>

      </nav>
    </div>

  )) || null
}


export default SideBar